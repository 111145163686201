import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

const app = createApp(App);

// Add axios to the global properties
app.config.globalProperties.$axios = axios;

// Use the router
app.use(router);

// Mount the app
app.mount("#app");
