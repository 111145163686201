<template>
  <div v-if="comment">
    <!-- Display the current comment details -->
    <CommentDetail :comment="comment" />
    <!-- Display child comments -->
    <ChildComments :comments="comment.children" />
  </div>
  <div v-else>
    <p>Loading comment...</p>
  </div>
</template>

<script>
import CommentDetail from "../components/CommentDetail.vue";
import ChildComments from "../components/ChildComments.vue";

export default {
  name: "CommentView",
  components: {
    CommentDetail,
    ChildComments,
  },
  data() {
    return {
      comment: null,
      error: null,
    };
  },
  methods: {
    fetchComment() {
      const { comment_public_id, comment_slug } = this.$route.params;
      this.comment = null;
      this.$axios
        .get(`/api/comments/${comment_public_id}/${comment_slug || ""}`)
        .then((response) => {
          this.comment = response.data;
        })
        .catch((error) => {
          console.error(error);
          this.error = "Failed to load comment.";
        });
    },
  },
  created() {
    this.fetchComment();
  },
  watch: {
    "$route.params.comment_public_id": "fetchComment",
    "$route.params.comment_slug": "fetchComment",
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
