<template>
  <div>
    <!-- New Header -->
    <header class="site-header">
      <div class="header-content">
        <div class="logo-title">
          <!-- Logo space -->
          <img src="./1.png" alt="Company Logo" class="logo" />
          <!-- Title space -->
          <span class="company-title">Breakup Buddy</span>
        </div>
        <!-- Download Link -->
        <div class="download-link">
          <button class="download-button" @click="downloadApp">Download</button>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <div class="home-container">
      <!-- Page Title -->
      <h1 class="page-title">Recent Posts</h1>

      <!-- Content (Posts) -->
      <div v-if="posts.length === 0" class="no-posts-message">
        <p>No posts available at the moment.</p>
      </div>

      <div v-else class="posts-list">
        <div v-for="post in posts" :key="post.publicUUID" class="post-preview">
          <router-link :to="`/posts/${post.publicUUID}/${post.slug}`">
            <div class="post-content">
              <div class="post-text-block">
                <h2 class="post-title">{{ post.title }}</h2>
                <p class="post-excerpt">
                  {{ post.text.substring(0, 200) }}
                  {{ post.text.length > 200 ? "..." : "" }}
                </p>
              </div>
              <div class="post-meta">
                <span class="post-likes">{{ post.likes }}</span>
                <span class="post-comments">
                  {{ post.comments_count }} comments
                </span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    downloadApp() {
      // Implement the logic to download the app
      // For example, redirect to a download page or start the download
      // window.location.href = "/path/to/your/app/download";
      // Or open in a new tab
      window.open(
        "https://apps.apple.com/us/app/breakup-buddy-heal-heartbreak/id1667956640",
        "_blank"
      );
    },
  },
  created() {
    this.$axios
      .get("/api/posts")
      .then((response) => {
        this.posts = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
/* Header Styling */
.site-header {
  width: 100%;
  background-color: #ffffff; /* Adjust color as needed */
  border-bottom: 1px solid #eaeaea;
  box-shadow: 0 2px 4px rgba(161, 161, 161, 0.1);
}

.header-content {
  max-width: 1200px; /* Adjust max width as needed */
  margin: 0 auto;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 15px;
  border-radius: 10px;
}

.company-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333333;
}

.download-button {
  padding: 8px 16px;
  background-color: #007bff; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Full width container that centers content */
.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Page title */
.page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

/* No posts available message */
.no-posts-message {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
  margin-top: 50px;
}

/* Posts List Container: Vertical Scroller */
.posts-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Post preview card */
.post-preview {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 20px;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Hover effect for post */
.post-preview:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-3px);
}

/* Thumbnail and title row */
.post-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Block for title and excerpt */
.post-text-block {
  max-width: 75%;
}

/* Post title */
.post-title {
  font-size: 1.5rem;
  margin: 0;
  color: #1f1f1f;
  font-weight: bold;
}

/* Post text (excerpt) */
.post-excerpt {
  font-size: 1rem;
  color: #666;
  margin: 10px 0 0;
  line-height: 1.5;
}

/* Post meta information */
.post-meta {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #777;
}

.post-meta span {
  margin-right: 10px;
}

/* Likes and comment count displayed prominently */
.post-likes,
.post-comments {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.post-likes::before {
  content: "👍";
}

.post-comments::before {
  content: "💬";
}

/* Router link covers entire card */
.router-link {
  text-decoration: none;
  display: block;
  color: inherit;
}

/* Mobile responsiveness for header and posts list */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .download-button {
    margin-top: 10px;
  }

  .company-title {
    font-size: 1.4rem;
  }

  .post-text-block {
    max-width: 100%;
  }

  .post-preview {
    padding: 15px;
  }

  .post-title {
    font-size: 1.3rem;
  }
}
</style>
